exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-author-page-index-js": () => import("./../../../src/templates/AuthorPage/index.js" /* webpackChunkName: "component---src-templates-author-page-index-js" */),
  "component---src-templates-blog-index-index-js": () => import("./../../../src/templates/BlogIndex/index.js" /* webpackChunkName: "component---src-templates-blog-index-index-js" */),
  "component---src-templates-blog-page-side-section-index-js": () => import("./../../../src/templates/BlogPageSideSection/index.js" /* webpackChunkName: "component---src-templates-blog-page-side-section-index-js" */),
  "component---src-templates-ebook-index-js": () => import("./../../../src/templates/Ebook/index.js" /* webpackChunkName: "component---src-templates-ebook-index-js" */),
  "component---src-templates-home-page-index-js": () => import("./../../../src/templates/HomePage/index.js" /* webpackChunkName: "component---src-templates-home-page-index-js" */),
  "component---src-templates-single-blog-index-js": () => import("./../../../src/templates/SingleBlog/index.js" /* webpackChunkName: "component---src-templates-single-blog-index-js" */),
  "component---src-templates-single-whats-new-release-index-js": () => import("./../../../src/templates/SingleWhatsNewRelease/index.js" /* webpackChunkName: "component---src-templates-single-whats-new-release-index-js" */),
  "component---src-templates-whats-new-index-index-js": () => import("./../../../src/templates/WhatsNewIndex/index.js" /* webpackChunkName: "component---src-templates-whats-new-index-index-js" */)
}

